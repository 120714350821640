.withdrawCard {
    margin-bottom: 3em;
    .cardHeader {
        display: flex;

        align-items: center;

        .headerIcon {
            height: 100%;
            margin-right: 1em;
            box-shadow: 0px 8px 16px rgb(226, 226, 226);
            border-radius: 28pt;
        }

        .headerText {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 28pt;
            line-height: 39px;
            display: inline-flex;
            align-items: center;
            .headerInfoIcon {
                color: rgba(0, 0, 0, 0.2);
                font-size: 0.7em;
                margin-left: 0.5em;
            }
        }
        margin-bottom: 1.5em;
    }

    .cardContent {
        background: #ffffff;
        /* Block */

        padding: 2em;
        padding-bottom: 1em;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
        border-radius: 4px;

        .input {
            margin-bottom: 0.5em;
            .inputFields {
                display: flex;

                .inputField {
                    background: rgba(0, 0, 0, 0.05);
                    border: none;
                    border-radius: 4px 0px 0px 4px;
                    color: black;
                    height: 3em;
                    font-size: 14pt;
                    padding-left: 1.5em;
                    width: 80%;
                }

                .inputFieldPostpend {
                    background: rgba(0, 0, 0, 0.05);
                    border: none;
                    border-radius: 0px 4px 4px 0px;
                    color: black;
                    text-align: center;

                    font-size: 14pt;
                    height: 3em;
                    width: 20%;
                }
            }

            .fee {
                font-family: "Cabin", sans-serif;
                margin: 0;
                height: 0;
                font-size: 10pt;
                p {
                    margin: 0;
                }
            }

            .inputHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .headerBalance {
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12pt;
                    line-height: 36pt;
                }

                .headerMax {
                    background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
                    border-radius: 4px;
                    border: none;

                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 8pt;

                    width: 48px;
                    height: 15pt;

                    color: #ffffff;
                    cursor: pointer;
                }
            }
        }

        .comissionSection {
            width: 100%;
            .numericValues {
                display: flex;
                justify-content: space-between;

                font-family: "Montserrat";
                font-style: normal;
                font-weight: 400;
                font-size: 1em;
            }

            .timeline {
                ul {
                    padding: 0;
                    display: flex;
                    justify-content: space-between;
                    list-style: none;
                    align-items: flex-start;

                    font-family: "Montserrat";
                    font-size: 0.75em;

                    li {
                        position: relative;
                        margin-top: 10px;
                        min-width: 1.5em;

                        img {
                            height: 16px;
                        }
                    }

                    .bar {
                        border-bottom: 2px solid #3db7f5;
                        width: 100%;
                        margin-left: -1em;
                        margin-right: -0.3em;
                    }

                    .barDisabled {
                        border-bottom: 2px solid #e5e5e5;
                        width: 100%;
                        margin-left: -1em;
                        margin-right: -0.3em;
                    }

                    li::before {
                        content: "";
                        width: 6px;
                        height: 6px;
                        background: #0aa7f5;
                        border: 2px solid #0aa7f5;
                        position: absolute;
                        border-radius: 6px;
                        top: -1.1em;
                        left: 0.3em;
                    }

                    .bigDot::before {
                        content: "";
                        width: 12px;
                        height: 12px;
                        background: #0aa7f5;
                        border: 2px solid #0aa7f5;
                        position: absolute;
                        border-radius: 12px;
                        top: -1.4em;
                        left: 0.3em;
                    }

                    .dotDisabled::before {
                        content: "";
                        width: 6px;
                        height: 6px;
                        background: #e5e5e5;
                        border: 2px solid #e5e5e5;
                        position: absolute;
                        border-radius: 6px;
                        top: -1.1em;
                        left: 0.3em;
                    }
                }
            }
        }

        .confirmationButton {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .withdrawAllButton {
                flex: 1 1 100%;
                margin-top: 0.5em;
                background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
                border-radius: 4px;
                color: white;
                border: none;
                height: 3em;

                font-family: "Montserrat", sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 1em;
                transition: 0.3s;
                cursor: pointer;
                letter-spacing: 0.02em;

                &:hover {
                    text-shadow: 0px 0px 8px #61c8fb53;
                }

                &:disabled {
                    filter: grayscale(1);
                }
            }

            .withdrawButton {
                flex: 0 1 49%;
                background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
                border-radius: 4px;
                color: white;
                border: none;
                height: 3em;
                width: 100%;
                font-size: 12pt;
                font-family: "Montserrat", sans-serif;
                cursor: pointer;
                transition: 0.3s;

                &:disabled {
                    filter: grayscale(1);
                }
            }

            .withdrawButton:hover {
                box-shadow: 0px 0px 10px rgba(0, 119, 255, 0.452);
            }

            .harvestButton {
                flex: 0 1 49%;
                width: 100%;
                background-color: rgba(0, 0, 0, 0);
                border-image: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%) 4;

                font-family: "Montserrat", sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 12pt;
                height: 3em;
                transition: 0.3s;

                &:disabled {
                    filter: grayscale(1);
                }

                .whiter {
                    background-color: white;
                }

                .gradientText {
                    background-image: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
                    background-clip: text;
                    color: transparent;
                }
                cursor: pointer;
            }

            .harvestButton:hover {
                box-shadow: 0px 0px 10px rgba(0, 119, 255, 0.452);
            }
        }

        .percentSlider {
            margin-top: 1em;
            margin-bottom: 1em;
        }
    }
}

@media (max-width: 820px) {
    .withdrawCard {
        .cardHeader {
            display: flex;

            align-items: center;

            .headerIcon {
                height: 100%;
                margin-right: 1em;
            }

            .headerText {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 21pt;
                line-height: 21pt;
            }
            margin-bottom: 0.5em;
        }

        .cardContent {
            padding: 1em;

            .input {
            }
        }
    }
}

@media (max-width: 1440px) {
    .withdrawCard {
        .cardHeader {
            display: flex;
            height: 28pt;

            align-items: center;
            margin-bottom: 1em;

            .headerIcon {
                height: 24pt;
                margin-right: 1em;
                box-shadow: 0px 8px 16px rgb(226, 226, 226);
                border-radius: 28pt;
            }

            .headerText {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 22pt;
                line-height: 28pt;
            }
        }
    }
}

@media (max-width: 1000px) and (orientation: portrait) {
    .withdrawCard {
        width: 100%;
        .cardHeader {
            .headerText {
                font-size: 19pt;
            }
            margin-bottom: 0.5em;
        }

        .cardContent {
            box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
        }
    }
}
