
.QnA {

    padding-top: 3em;
    padding-bottom: 3em;
    .innerQnA{

        max-width: 1300px;
        // width: 80%;
        display: flex;
        align-items: center;
        h1 {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 800;
            font-size: 4em;
            line-height: 1em;
            /* identical to box height */
    
            color: #001d9a;
            margin-bottom: 0.3em;
            margin-right: 2em;
        }
    
        .questions {
            .collapsible{
                width: 100%;
                margin-bottom: 1em;
                .trigger {
                    .innerTrigger{
                        display: flex;
                        padding-bottom: 0.25em;
                        border-bottom: 2px solid rgba(3, 24, 255, 0.088);

                        .icon{
                            font-size: 1em;
                            margin-right: 0.3em;
                            margin-bottom: -0.1em;
                        }
                    }
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    color: #5f6cac;
                    cursor: pointer;

                }
    
                .answer{
                    margin-top: 0.5em;
                    font-weight: 500;
                }
            }
    
        }
    }

}

@media (max-width: 880px) and (orientation: portrait){
    .QnA {
        padding-top: 0em;
        padding-bottom: 0em;
        padding-left: 5vw;
        padding-right: 5vw;
        .innerQnA{
            max-width: 100vw;
            flex-direction: column;

            h1{
                font-size: 2em;
            }

             .questions{
                width: 90%;
             }

        }
    }
}