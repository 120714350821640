.TableHeader{
    display: flex;
    flex-direction: row;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 17px;
    color: #000000;
    padding: 24px;
}