.Leaderboard {
    margin-top: 4vh;
    header {
        display: flex;
        align-items: center;
        margin-bottom: 1%;

        img {
            height: 28pt;
            margin-right: 1em;
            box-shadow: 0px 8px 16px rgb(226, 226, 226);
            border-radius: 56pt;
        }

        h1 {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 28pt;
            line-height: 35pt;
            color: black;
        }
    }

    main {
        padding: 0;
        .table {
            
        }
    }
}

@media (max-width: 1440px) {
    .Leaderboard{
        header{
            img{
                height: 22pt
            }

            h1{
                font-size: 22pt;
            }
        }
    }
}