.Footer{
    background-color: #131415;
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    flex-direction: row;
    justify-content: center;
    padding-top: 1em;

    .container{
        max-width: 1440px;
        width: 100%;
        margin-left: 5vw;
        margin-right: 5vw;
    }

    .separator{
        height: 0;
        border-top: 1px solid #333436;
        margin-bottom: 1.5em;
        margin-top: 1.5em;
    }

    padding-bottom: 8vh;
}

@media(max-width: 1440px){
    .Footer{
        .container{
            max-width: 75vw;
        }
    }
}

@media(max-width: 1000px){
    .Footer{
        width: 100vw;
        .container{
            max-width: 100vw;
            width: 100%;
            margin-left: 0.8em;
        }
    }
}