.ProviderDialog {
    display: flex;
    flex-direction: column;
    width: 100%;

    .providerButton {
        margin-top: 30px;
        font-family: "Cabin", sans-serif;
        background-color: rgb(239, 239, 239);
        border: none;
        font-size: 18pt;
        font-weight: 600;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 10px;
        border-radius: 8px;
        transition: all 0.25s ease-out;
        display: inline-flex;
        align-items: center;
    }

    .providerButton:active {
        background-color: rgb(230, 230, 230);
    }

    .providerButton:last-child {
        margin-bottom: 0;
    }

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        color: #000000;
        padding-left: 2rem;
        margin-top: 38px;
    }

    .buttons {
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0rem 2rem 0rem 2rem;
        .providerButton1 {
            width: 100%;
            height: 60px;
            background: rgba(245, 132, 31, 0.1);
            border-radius: 4px;
            border: none;
            margin-top: 20px;
            cursor: pointer;
        }
        .providerButton2 {
            width: 100%;
            height: 60px;
            background: rgba(0, 0, 0, 0.08);
            border-radius: 4px;
            border: none;
            margin-top: 20px;
            cursor: pointer;
        }
        .inlineLogo {
            height: 1.2em;
            margin-right: 6px;
        }
    }
}
