.IdoBlock {
    width: calc(50% - 1.5em) !important;
    margin-bottom: 36px;
    background: #ffffff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    cursor: pointer;
    z-index: 0;
    height: fit-content;

    header{
        padding-top: 3em;
        padding-bottom: 3em;
        
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 2em;
        margin-bottom: 0 !important;
            
        .bgImage{
            object-fit: cover;
            top: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: 100%;
            
            position: absolute;
            z-index: 0;
            border-radius: 12px;
            filter: brightness(50%);
        }
    

        .tokenBlock {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            z-index: 0;
        
            .token {
                display: flex;
                flex-direction: row;
        
                img {
                    margin-left: -10px;
                    max-width: 50%;
                    height: auto !important;
                }
        
                .text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 15px;
                    color: white;
                }
        
                .name {
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 900;
                    font-size: 32pt !important;
                    line-height: 39px;
                    color: #000000;
                    color: white;
                }
        
                .price {
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: #000000;
                }
            }
        
            .progresLabel {
                display: flex;
                flex-direction: column;
                justify-content: center;
                .styledLabel {
                    background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
                    border-radius: 4px 0px 0px 4px;
                    padding: 4px 7px;
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    color: #ffffff;
                }
            }
        }
    }

    main{
        border-radius: 12px;
        box-shadow: 0px -5px 10px -3px rgba(255, 255, 255, 0.794);
        transform: translate(0, -1em);
        z-index: 2;
        background-color: white;
        padding-bottom: 0 !important;
        padding-top: 1.5em !important;
    }
}

.progressBar{
    position: relative;
    height: 30px;
    margin: 20px 0px;
    .backPart{
        background: #000000;
        opacity: 0.1;
        border-radius: 4px;
        width: 100%;
        height: 24px;
        position: absolute;
    }

    .topPart{
        background: linear-gradient(180deg, #0AA7F5 0%, #3CE7FF 100%);
        border-radius: 4px;
        height: 24px;
        position: absolute;
        max-width: 100%;
    }

}



.saleInfo {
    .privateSaleFlag{
        font-weight: 500;
        position: absolute;
        top: -0.8em;
        right: 0;
        font-family: 'Montserrat';
        color: white;
        background: linear-gradient(180deg, #0AA7F5 0%, #3CE7FF 100%);
        padding: 0.3em 1em 0.3em 1em;
        font-size: calc(12px + 0.2vw);
        border-radius: 4px 4px 4px 4px;
    }

    .line {
        height: 2px;
        width: 100%;
        background: #000000;
        opacity: 0.1;
        margin-top: 2vh;
    }
}

.totalRaised {
    margin-top: 5px;

    .title{
        font-family: "Montserrat";
        font-weight: 600;
        font-style: normal;
        font-size: 20pt;
        line-height: 14pt;
        color: #000000;
        margin-bottom: 15px;

    }
    .text {
        font-family: "Montserrat";
        font-weight: 400;
        font-style: normal;
        font-size: 12pt;
        line-height: 14pt;
        color: #000000;

    }

    .count {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 24pt;
        margin-top: 2pt;
        background: -webkit-linear-gradient(#0aa7f5, #3ce7ff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.textToShowBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3vh;

    .textToShow {
        display: flex;
        flex-direction: column;
        
        .text {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 14pt;
            line-height: 20px;
            color: #000000;
        }
        .value {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
        }
    }
}

.launchDetaid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 39px;
    .block {
        display: flex;
        flex-direction: column;
    }

    .subBlock {
        display: flex;
        align-items: baseline;
        flex-direction: row;
        margin-bottom: 10px;
        margin-right: 10px;
    }

    .text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 10pt;
        line-height: 10pt;
        color: #000000;
        margin-right: 10px;
    }

    .value {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 12pt;
        line-height: 13pt;
        color: #0495ce;
        display: flex;
        flex-direction: column;
        justify-content: end;
    }
}
@media (min-width: 350px) {
    .IdoBlock {
        width: 100%;
        margin-right: 0px;
    }
}

@media (min-width: 960px) {
    .IdoBlock {
        width: 100%;
        margin-right: 0px;
    }
}

@media (min-width: 1280px) {
    .IdoBlock {
        width: calc(50% - 5vw);
        margin-right: 1vw;
        margin-bottom: 1vw;
        main{
            padding: 2vw;
        }
    }
}

@media (max-width: 1440px) {

    .tokenBlock{
        .token{
            .text{
                font-size: 10pt;
            }

            .name{
                font-size: 20pt;
            }
        }
    }

    .totalRaised {
        margin-top: 0px;
        .text{
            font-size: 10pt;
            line-height: 12pt;
        }

        .count{
            font-size: 18pt;
        }
    }

    .textToShowBlock {   
        .textToShow {
            display: flex;
            flex-direction: column;
            
            .text {
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 10pt;
        
                color: #000000;
            }
            .value {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 14pt;
                color: #000000;
            }
        }
    }

    .launchDetaid{
        .text {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 9pt;
            line-height: 9pt;
            color: #000000;
            margin-right: 10px;
        }
    
        .value {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 9pt;
            line-height: 9pt;
            color: #0495ce;
            display: flex;
            flex-direction: column;
            justify-content: end;
        }
    }
}

@media (max-width: 1000px) and (orientation: portrait){
    .IdoBlock {
        width: 100% !important;
        margin-right: 0;
        margin-bottom: 30px;
        background: #ffffff;
        box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
       

        cursor: pointer;

        header{
            .tokenBlock{
                .token{

                    img{
                        height: 3em;

                    }
                    .name{
                        font-size: 20pt !important;
                    }
                }
            }
        }

        main{
            padding: 1.5em;
        }
    }

    .totalRaised {
        margin-top: 0px;
        .text{
            font-size: 8pt;
            line-height: 12pt;
        }

        .count{
            font-size: 14pt;
        }
    }

    .textToShowBlock {   
        margin-top: 0;
        .textToShow {
   
            
            .text {
    
                font-size: 8pt;

            }
            .value {
                font-size: 12pt;
                color: #000000;
            }
        }
    }

    .progressBar{
        margin: 0;
    }

    .launchDetaid {
        margin-top: 15px;

        .subBlock {
            
            margin-right: 5px;
        }
    
        .text {
   
            font-size: 6pt;
            line-height: 6pt;
            margin-right: 5px;
        }
    
        .value {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 8pt;
            line-height: 8pt;
            color: #0495ce;
            display: flex;
            flex-direction: column;
            justify-content: end;
        }
    }
}