.EarnWithCrew3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;

    heading {
        color: white;
        font-family: "Montserrat";
        font-weight: 500;
        margin-bottom: 0.25em;
    }

    .subheading {
        margin-bottom: 2rem;
    }

    .heading {
        font-size: 3rem;
    }

    .video {
        min-width: 900px;
        min-height: 50vh;
        margin-bottom: 2rem;
    }

    .ctaButton {
        background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
        font-weight: 600;
        border-radius: 4px;
        color: white;
        border: none;
        padding: 0.75em 2em 0.75em 2em;
        font-size: 1.25rem;
        font-family: "Montserrat", sans-serif;
        cursor: pointer;
        transition: 0.3s;
    }
}

@media (max-width: 756px) {
    .EarnWithCrew3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 90vh;

    
        .video {
            min-width: 0 !important;
            width: 90vw;
            min-height: 0 !important;
            height: 40vh;
            margin-bottom: 2rem;
        }
    
    }
}
