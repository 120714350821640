.anchorContainer {
    position: relative;

    .popoverContainer {
        color: white;
        background: #348ff6;
        border-radius: 8px;
        position: absolute;
        top: 4em;
        left: 0;
        width: 10em;
        box-shadow: 0 1px 8px rgba(255, 255, 255, 0.104);

        transform: translateY(-20px);
        transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
        padding: 0.5rem;

        display: flex;
        flex-direction: column;

        button {
            display: flex;
            align-items: center;
            padding-top: 0.45em;
            padding-bottom: 0.45em;
            padding-left: 1em;
            font-family: "Cabin", sans-serif;
            font-size: 1rem;
            transition: all 0.25s ease-in-out;
            background-color: transparent;
            border: none;
            outline: none;
            color: white;
            border-radius: 0.5rem;

            cursor: pointer;

            .icon {
                margin-right: 0.5em;
                font-size: 1.3em;
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.136) !important;
            }
        }
    }
}
