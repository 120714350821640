.ButtonLight {
    background: #0C67FE;;
    border: 4px solid #96BDFF;
    border-radius: 12px;

    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 1em;
    color: white;
    height: 3.25em;
    padding-left: 2em;
    padding-right: 2em;

    cursor: pointer;
}
