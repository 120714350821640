.BuyPeak {
    background-color: none;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5em;
    padding-bottom: 2em;
    border-radius: 10px;

    margin-top: 0vh;

    h3 {
        font-family: "Montserrat", sans-serif;
        font-size: 16pt;
        color: white;

        margin-bottom: 0.5em;
        margin-top: 0;
        margin-left: 1em;
        margin-right: 1em;

        width: fit-content;
        font-weight: 600;
        margin-bottom: 0.5em;
        vertical-align: middle !important;
        width: 100%;
        a {
            vertical-align: middle;
            margin-left: 0.5em;
            margin-right: 0.5em;
        }
    }

    .links {
        display: flex;
        // justify-content: space-around;
        a {
            display: flex;
            padding: 12px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            background: white;
            box-shadow: 4px 4px 6px 0px white;
            height: 24px;
            margin-right: 12px;
        }
        width: 100%;
        align-items: center;
        a {
            max-width: 11%;
            img {
                max-width: 70px;
                transition: all 0.5s ease-out;
                // filter: opacity(0.55);
                // margin-right: 20px;
            }

            img:hover {
                transform: scale(1.05);
                filter: opacity(1);
            }
        }
    }
}

@media (max-width: 1500px) {
    .BuyPeak {
        margin-top: 0vh;
    }
}

@media (max-width: 1000px) and (orientation: portrait) {
    .BuyPeak {
        h3 {
            margin-right: 0.75em;
            margin-left: 0.75em;
            font-size: 12pt;

            a {
                img {
                    height: 1.5em;
                }
            }
        }

        .links {
            flex-wrap: wrap;
            justify-content: flex-start;
            a {
                margin-bottom: 0.75rem;
                max-width: calc(100% / 5 - 5px);

                img {
                    max-width: 100%;
                }
            }
        }
    }
}
