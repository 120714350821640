.NotFound{
    header{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 95vh;

        h1{
            color: rgb(1, 64, 92);
            font-family: "Montserrat", sans-serif;
            text-transform: uppercase;
            font-size: 25pt;
        }

        .c404{
            color: rgb(0, 41, 58);;
            font-family: "Montserrat", sans-serif;
            text-transform: uppercase;
            font-size: 130pt;
            margin-bottom: 30px;
            font-weight: 300;
        }

        p{
            font-size: 16pt;
            color: rgb(89, 89, 89);
            max-width: 50%;
            text-align: center;
        }

        .goHome{
            border: none;
            font-family: "Montserrat", sans-serif;
            font-size: 16pt;
            font-weight: 600;
            color: white;
            padding: 10px;
            padding-left: 1em;
            padding-right: 1em;
            border-radius: 10px;
            background-color: rgb(0, 122, 245);
            cursor: pointer;
            transition: 0.5s all ease-in-out;
        }

        .goHome:hover{
            transform: scale(1.05);
        }
    }
}

@media (max-width: 1000px) {
    .NotFound{
        header{
            p{
                max-width: 100%;
            }
        }
    }
}