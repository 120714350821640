.adminPanel {

    margin-bottom: 100px;
    header {
        .mainText {
            margin-top: 2em;
            max-width: 60vw;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: bold;
            font-size: 8vh;

            color: #ffffff;
        }

        

        .buttons{
            display: flex;

            .infoButton {
                width: 204px;
                height: 52px;
                margin-right: 1em;
    
                margin-top: 4vh;
    
                border: 2px solid #ffffff;
                box-sizing: border-box;
                border-radius: 10px;
                text-align: center;
                vertical-align: 10em;
                color: white;
    
                font-family: 'Cabin', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 52px;
                cursor: pointer;
                background-color: transparent;
                transition: 0.5s;
            }
    
            .infoButton:hover{
                background-color: rgba(255, 255, 255, 0.2);
            }
            
            margin-bottom: 15vh;
        }
    }

    .confirmButton {
        margin-right: 1em;
    }

    section {
        display: flex;
        align-items: center;

        .formSection {
            width: 100%;
        }

        .tablesSection {
            .tableDiv {
                margin-bottom: 2em;
            }

            width: 100%;

            .collapsibleContent{
                background-color: white;
                padding: 10px;
                border-radius: 10px;
                box-shadow: 0px 0px 15px rgba(139, 139, 139, 0.233);
            }

            .collapsibleHeader {
                font-family: "Cabin", sans-serif;
                font-size: 36pt;
                font-weight: 600;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 0.1em;
                border-bottom: 1px solid rgb(235, 235, 235);
                background-color: rgba(255, 255, 255, 0.561);
                padding: 10px;
                border-radius: 10px;
                box-shadow: 0px 0px 15px rgba(139, 139, 139, 0.233);
                transition: 1s;
            }

            .collapsibleHeader {
                transition: 1s;
            }

            .collapsibleHeaderisOpen {
                background-color: white;
                font-family: "Cabin", sans-serif;
                font-size: 36pt;
                font-weight: 600;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 0.1em;
                border-bottom: 1px solid rgb(235, 235, 235);
                background-color: white;
                padding: 10px;
                border-radius: 10px;
                box-shadow: 0px 0px 15px rgba(139, 139, 139, 0.233);
                transition: 1s;
            }

            .collapsibleHeaderisOpen svg {
                transform: rotate(180deg);
                transition: 1s;
            }

            .sectionHeader {
                border-bottom: 1px solid rgb(158, 158, 158);
                display: flex;
                justify-content: space-between;
                align-items: center;
                h1 {
                    font-family: "Cabin", sans-serif;
                }
            }
        }
    }
}

@media(max-width: 900px){
    .adminPanel{

        header {
            .mainText {
                margin-top: 50px;
                max-width: 60vw;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: bold;
                font-size: 32pt;
    
                color: #000000;
            }
            margin-bottom: 1em;
        }
        
        section{
            flex-direction: column;
            .formSection {
                width: 100%;
            }

            .tablesSection{
                width: 100%;
            }
        }
    }
}

@media (min-width: 1500px){
    .adminPanel{
        header{
            .mainText{
                margin-top: 15vh;
              
            }
            margin-bottom: 10vh;
        }

        section{
            .formSection{
                margin-top: 14vh;
            }
        }
    }
}

