.ComissionFilter {
    display: flex;
    align-items: center;
    .hyphen {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 800;
        font-size: 1em;
        margin-left: 0.75em;
        margin-right: 0.75em;
        color: black
    }

    select {
        width: 8em;
        background: rgba(0, 0, 0, 0.05);;
        border: none;
        border-radius: 4px;

        height: 2.8em;
        outline: none;

        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 1em;
        color: black;
        padding-left: 1em;
        padding-right: 2em;

        option :disabled{
            color: #9dadf7;
        }
    }
}
