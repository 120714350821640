.MediaLinks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.8em;


    h6 {
        color: #6e84a3!important;
        font-family: "Cerebri Sans",sans-serif;
        font-size: .9375rem;
        font-weight: 400;
        line-height: 1.5;
        text-align: left;
        margin: 0;
    }

    .media {
        a {
            margin-right: 0.7em;
            color: white;
            font-size: 18px;
            display: inline-flex;
            align-items: baseline;
            img {
                height: 18px;
            }
        }

        .emailSection{
            margin-top: 0.2em;
            display: flex;
            align-items: center;

            .emailAddress{
                color: rgba(255, 255, 255, 0.831);
                font-size: 0.9em;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
}
