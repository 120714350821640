.ThankYouPage {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
        margin-top: 10vh;
        height: 15vmax;
        margin-bottom: 6vh;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        border-radius: 100%;
    }

    h1 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 4.5vh;
        text-align: center;
        max-width: 15em;
        color: #000000;
        margin-bottom: 6vh;

        height: auto;
    }

    .doneButton {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14pt;
        text-align: center;
        color: #ffffff;

        width: 10em;
        height: 2.5em;
        border: none;
        background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
        border-radius: 50px;
        transition: all 0.5s;

        &:hover{
            transform: scale(1.05);
        }
    }
}
