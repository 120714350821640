.withdrawElement {
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  .withdrawElementContent {
    padding: 2.5em;

    .FooterContainer {
      margin-top: 2.5em;
      display: flex;
      justify-content: space-between;
      .FooterItemTitle {
        color: #000;
        font-family: Montserrat;
        font-size: 0.875em;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        opacity: 0.5;
      }
      .FooterItemText {
        margin-top: 0.5em;
        color: #000;
        font-family: Montserrat;
        font-size: 1em;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .withdrawHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2em;
    .TokenInfoContainer {
      display: flex;
      .TokenNames {
        padding-left: 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.25em;
        .TokenName {
          font-family: Montserrat;
          font-size: 1em;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .TokenShortName {
          color: #000;
          font-family: Montserrat;
          font-size: 0.875em;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
    .TimerContainer {
      align-items: center;
      display: flex;
      flex-direction: row;
      .TimerTitel {
        display: flex;
        justify-content: center;
        color: #000;
        font-family: Montserrat;
        font-size: 0.875em;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        opacity: 0.5;
        margin-right: 1.125em;
      }
      .Timer {
        padding-left: 1.125em;
        padding-right: 1.125em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        border-radius: 0.75em;
        border: 1px solid rgba($color: #000000, $alpha: 0.1);
        display: flex;
        .TimerItemContainer {
          .TimerNumberContainer {
            display: flex;
            .TimerItemNumber {
              color: #000;
              font-family: Montserrat;
              font-size: 1.25em;
              font-weight: 600;
            }
          }
          .TimerItemText {
            text-align: start;
            color: #000;
            font-family: Montserrat;
            font-size: 0.875em;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            opacity: 0.5;
          }
        }
      }
    }
    .ButtonContainer {
      cursor: pointer;
      border-radius: 0.875em;
      background: #e8e25c;
      margin-left: 1.125em;

      padding-left: 2.875em;
      padding-right: 2.875em;
      padding-top: 0.875em;
      padding-bottom: 0.875em;
      color: #1039a5;
      font-family: Montserrat;
      font-size: 1em;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .ButtonContainer2 {
      cursor: pointer;
      border-radius: 0.875em;
      background: #1039a5;
      margin-left: 1.125em;

      padding-left: 0.875em;
      padding-right: 0.875em;
      padding-top: 0.875em;
      padding-bottom: 0.875em;
      color: #e8e25c;
      font-family: Montserrat;
      font-size: 1em;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .withdrawTokenLogo {
      width: 3.75em;
      height: 3.75em;
      border-radius: 4.5em;
    }
  }
  .withdrawLine {
    width: 100%;
    opacity: 0.1;
    background: #000;
    height: 1px;
  }

  .polygonNetwork {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    .switchNetworksButton {
      background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
      border-radius: 4px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      color: #ffffff;
      border: 0px;
      padding: 0.75em 1.5em 0.75em 1.5em;
      cursor: pointer;
    }
  }
}
