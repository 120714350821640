

.valuePriceCard{
    min-width: 35vw;
    height: 8em;
    background: #ffffff;
    padding-left: 1em;
    padding-right: 1em;
    /* Block */

    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.192);
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding-left: 1em;
    padding-right: 3em;

    .verticalSplitter{
        width: 1px;
        border-left: 1px solid rgb(231, 231, 231);
        height: 50%;
    }


    .subsection{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;

        .iconDiv{
            display: flex;
            align-items: center;
            height: 100%;

            .icons{
                height: 65%;   
            }
        }

        .subsectionTitle{
            font-family: 'Montserrat', sans-serif;
            font-size: 14pt;
        }

        .subsectionContent{
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 28pt;
            /* identical to box height */
            color: #000000;
        }
    }
    
}


@media (max-width: 1440px ) and (min-width: 1000px){
    .valuePriceCard{
        height: 6em;
        width: 32vw;
        min-width: 32vw;

        .subsection{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 100%;
    
            .iconDiv{
                display: flex;
                align-items: center;
                height: 100%;
    
                .icons{
                    height: 65%;   
                }
            }
    
            .subsectionTitle{
                font-family: 'Montserrat', sans-serif;
                font-size: 10pt;
            }
    
            .subsectionContent{
                font-family: 'Montserrat', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 20pt;
                /* identical to box height */
                color: #000000;
            }
        }
    }
}

@media (max-width: 1000px) and (orientation: portrait) {
    .valuePriceCard{
        width: 100%;
        max-width: 100%;
        height: 10vh;
        
        .subsection{


            .subsectionTitle{
                font-family: 'Montserrat', sans-serif;
                font-size: 12pt;
            }
    
            .subsectionContent{
                font-family: 'Montserrat', sans-serif;
                font-style: normal;
                font-weight: 800;
                font-size: 14pt;
                /* identical to box height */
                color: #000000;
            }
        }
    }
}
