.FAQ {
    header {
        min-height: 35vh;
        display: flex;

        justify-content: center;
        flex-direction: column;

        h1 {
            color: white;
            font-family: "Montserrat", sans-serif;
            font-size: 50pt;
            letter-spacing: 0.03em;
        }
        p {
            color: white;
            font-family: "Montserrat", sans-serif;
            font-size: 20pt;
        }
    }

    section{
        margin-bottom: 3em;
        h2{
            font-family: 'Cabin', sans-serif;
            font-size: 25pt;
            margin-bottom: 0.5em;
        }

        p{
            font-family: "Montserrat";
            font-weight: 400;
            font-size: 16pt;
            margin-bottom: 0;
            margin-top: 0.5em;
            
        }

        ul{
            font-family: "Montserrat";
            list-style: none;
            padding: 0;
            font-size: 15pt;
        }
    }
}

@media (max-width: 1000px){
    .FAQ{
        header{
            min-height: 25vh;
            margin-bottom: 10vh;
            h1{
  
                font-size: 32pt;
            }
        }
    }
}