.BG{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    display: flex;
    align-items: flex-end;
    height: 80vh;

    .bgimg{

        width: 100%;
        height: 100%;

        object-fit: cover;
        position: absolute;
        z-index: -1;
        
    }

    .wave{
        z-index: 0;
    }

}

.imgBG{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    display: flex;
    align-items: flex-end;
    height: 73vh;

    .bgimg{

        width: 100%;
        height: 100%;

        object-fit: cover;
        position: absolute;
        object-position: right;
        z-index: -1;
        
    }

    .wave{
        z-index: 0;
        width: 100%;
    }
}

@media (max-width: 1440px){
    .BG{
        height: 60vh;
    }

    .imgBG{
        height: 55vh;
    }
}

@media (max-width: 1000px) and (orientation: portrait){
    .BG{
        height: 80vh;
        .bgimg{
            min-height: 80vh;
            object-fit: cover;
        }
    }
}

@media (min-width: 2000px) {
    .imgBG{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -1;
        display: flex;
        align-items: flex-end;
        height: 60vh;
    }
}