$font-global: "Monseratt", sans-serif;

.mainInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
  padding-bottom: 4rem;

  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 75px;
    line-height: 49px;
    color: #ffffff;
  }

  .text {
    margin: 20px 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    min-height: 5em;
  }
  button {
    z-index: 1;
    margin-right: 10px;
    width: 10em;
    height: 35pt;
    background: #ffffff;
    border-radius: 35pt;
    border-color: #ffffff;
    border-width: 0px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12pt;

    /* identical to box height */
    cursor: pointer;

    color: #000000;
  }

  .media {
    a {
      margin-right: 10px;
      text-decoration: none;
      img {
        background: white !important;
      }
    }
  }

  .actionBlock {
    display: flex;
    align-items: center;

    .text {
      font-size: 11pt;
    }

    .buttonBlock {
      margin-right: 1.5em;

      .depositWarning {
        margin-top: 0;
        color: rgb(255, 197, 197);
        background-color: rgba(255, 255, 255, 0.278);
        width: fit-content;
        margin-left: 1em;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        padding: 10px;
      }

      .inputs {
        display: flex;

        .inputFieldWrapper {
          margin-right: -60px;
          display: flex;
          position: relative;

          label {
            position: absolute;
            right: 5em;
            color: white;
            font-family: "Monseratt", sans-serif;
            font-weight: 600;
            top: 50%;
            transform: translateY(-51%);
            font-size: 100%;
          }

          .inputField {
            background: rgba(255, 255, 255, 0.2);
            border-radius: 35pt;
            border-width: 0px;
            color: white;
            border: none;
            height: 35pt;
            font-size: 14pt;
            font-weight: 900;
            padding-left: 1.5em;
            width: 80%;

            font-family: "Montserrat", sans-serif;
            outline: none;
            padding-top: 0;
            padding-bottom: 0;
            z-index: 0;
            position: relative;
          }

          .inputField:focus {
            outline: none;
            border: none;
          }

          .inputFieldWrapperDIV {
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: white;
            margin-right: 5px;
          }

          .max {
            color: white;
            position: absolute;
            right: 70px;
            top: calc(60% - 12pt) !important;

            font-size: 12pt !important;
            border-radius: 8px 8px 0px 0px;
            font-weight: 700;
            cursor: pointer;
            transition: all 1s ease-out;
          }

          .max:hover {
            transform: scale(1.1);
          }
        }
      }

      button {
        z-index: 1;
        margin-right: 10px;
        min-width: 10em;
        width: fit-content;
        height: auto;
        min-height: 35pt;
        background: #ffffff;
        border-radius: 35pt;
        border-color: #ffffff;
        border-width: 0px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 100%;

        /* identical to box height */
        cursor: pointer;

        color: #000000;
        transition: all 0.5s ease-out;
      }

      button:hover {
        box-shadow: 0px 0px 10px white;
      }

      button:disabled {
        background: rgb(52, 195, 252);
        color: white;
        cursor: default;
        box-shadow: none;
      }
    }

    .mediaMobile {
      display: flex;
      flex-wrap: nowrap;
      a {
        margin-right: 10px;
        text-decoration: none;
        img {
          background: rgb(255, 255, 255) !important;
          border-radius: 28px;
          height: 28px;
          width: auto;
        }
      }
    }
  }
}

@media (min-width: 350px) {
  .mainInfo {
    .title {
      color: rgb(255, 255, 255);
      margin-bottom: 1em !important;
    }
    .text {
      color: rgb(255, 255, 255);
      margin-bottom: 10px;
    }
    .media {
      display: none;
      color: #000;
    }
    .mediaMobile {
      display: block;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

@media (min-width: 1280px) {
  .mainInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 75px;
      line-height: 49px;
      color: #ffffff;
    }

    .text {
      margin: 20px 0;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
    }

    .media {
      a {
        margin-right: 10px;
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 1560px) {
  .mainInfo {
    margin-top: 0;

    .title {
      font-size: 25pt;
      margin-top: 1%;
      line-height: 24pt;
    }

    .actionBlock {
      margin-top: 23%;
      .text {
        font-size: 9pt;
      }

      .mediaMobile {
        min-width: 100px;
        a {
          margin-right: 5px;
          text-decoration: none;
          img {
            background: rgb(255, 255, 255) !important;
            border-radius: 24px;
            height: 24px;
            width: auto;
          }
        }
      }

      .buttonBlock {
        .inputs {
          display: flex;

          .inputFieldWrapper {
            .inputField {
              height: auto;
              min-height: 25pt;
              font-size: 12pt;
            }
          }
        }

        button {
          height: auto;
          min-height: 25pt;
          font-size: 11pt;
          min-height: fit-content;
          width: fit-content;
          padding-left: 2em;
          padding-right: 2em;
        }

        button:disabled {
          background: rgb(52, 195, 252);
          color: white;
          cursor: default;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .mainInfo {
    margin-top: 0;

    .title {
      font-size: 35pt;
      margin-top: 10%;
      line-height: 24pt;
    }

    .text {
      font-size: 12pt;
      margin: 0;
      margin-bottom: 1em;

      overflow-y: auto;
    }

    .actionBlock {
      margin-top: 23%;
      .text {
        font-size: 9pt;
      }

      .mediaMobile {
        min-width: 100px;
        a {
          margin-right: 5px;
          text-decoration: none;
          img {
            background: rgb(255, 255, 255) !important;
            border-radius: 24px;
            height: 24px;
            width: auto;
          }
        }
      }

      .buttonBlock {
        .inputs {
          display: flex;

          .inputFieldWrapper {
            .inputField {
              height: auto;
              min-height: 25pt;
              font-size: 12pt;
            }
          }
        }

        button {
          height: auto;
          min-height: 25pt;
          font-size: 11pt;
          min-height: fit-content;
          width: fit-content;
          padding-left: 2em;
          padding-right: 2em;
        }

        button:disabled {
          background: rgb(52, 195, 252);
          color: white;
          cursor: default;
        }
      }
    }
  }
}
@media (max-width: 1400px) and (min-width: 1000px) {
  .mainInfo {
    .textBlock {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .title {
      justify-content: center;
      align-items: center;
      font-size: 30pt;
    }
  }
}

@media (max-width: 1000px) and (orientation: portrait) {
  .mainInfo {
    .title {
      font-size: 30pt;
      line-height: 25pt;
      margin-top: 0.6em;
      margin-bottom: 0.3em !important;
    }

    .text {
      font-size: 11pt;
    }

    .mobileLogoDiv {
      width: 100%;
      display: flex;
      justify-content: center;
      .mobileLogo {
        max-width: 50%;
        max-height: 15vh;
        margin-top: 2vh;
        padding: 5px;
        border-radius: 8px;
        -webkit-filter: drop-shadow(0px 0px 10px #ffffff);
        filter: drop-shadow(0px 0px 10px #ffffff);
      }
    }

    .mediaMobile {
      margin-bottom: 1em !important;
    }

    .actionBlock {
      margin-top: 0;
      .text {
        font-size: 9pt;
        line-height: 9pt;
        color: rgb(216, 215, 215) !important;
      }
    }
  }
}

@media (max-width: 800px) {
  .mainInfo {
    display: none;
  }
}
