.TermsAndConditions{
    header{
        h1{
            margin-top: 10vh;
            color: white !important;
            font-size: 3.6vw;
        }
        
        margin-bottom: 20vh;
    }

    main{

    }
}