.allocationStaking {
    margin-bottom: 5vh;

    .chatTitle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-weight: bold;
        font-size: 24px;
        margin: 15px 0px;
    }
    .pageTitle {
        margin-top: 8em;
        height: 10%;
        display: flex;
        justify-content: space-between;

        .mainText {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: bold;
            font-size: 6vh;
            margin-top: 1em;
            margin-bottom: 10vh;
            max-width: 80%;

            color: #ffffff;

            div {
                vertical-align: middle;
                svg {
                    vertical-align: middle;
                    color: rgba(255, 255, 255, 0.351);
                    margin-left: 0.4em;
                    font-size: 3vh;
                    cursor: pointer;
                    transition: all 1s ease-in-out;
                    margin-bottom: 0.2em;
                }

                svg:hover {
                    color: white;
                }
            }
        }

        .infoButton {
            width: 204px;
            max-width: 15vw;
            height: 52px;
            max-height: 7vh;

            margin-top: 4vh;
            display: flex;
            align-items: center;
            justify-content: center;

            border: 2px solid #ffffff;
            box-sizing: border-box;
            border-radius: 52px;
            text-align: center;
            vertical-align: 10em;
            color: white;

            font-family: "Cabin", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 150%;

            cursor: pointer;
            background-color: transparent;
            transition: 0.5s;
        }

        .infoButton:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }

    .vpCard {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .switcherContainer {
        margin-top: 5rem;
        margin-bottom: 0.5rem;
    }

    .pageContent {
        display: flex;

        justify-content: space-between;
        gap: 2rem;

        .column {
            width: 100%;
        }
    }
}

@media (max-width: 900px) {
    .allocationStaking {
        .pageTitle {
            .mainText {
                max-width: 80vw;
                // width: 752px;
                color: black;
                font-family: Montserrat;
                font-style: normal;
                font-weight: bold;
                font-size: 3.5vh;
                height: 1em;
                margin-top: 28vw;
                margin-bottom: 3em;
            }

            .infoButton {
                border: 2px solid #000000;
                color: rgb(0, 0, 0);
                margin-top: 4rem;
            }
        }

        .vpCard {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 4vh;
        }

        .pageContent {
            flex-direction: column;
            margin-top: 5vh;

            .column {
                width: 100%;
            }
        }
    }
}

@media (max-width: 1440px) {
    .allocationStaking {
        .pageTitle {
            margin-top: 5em;
            .mainText {
                font-size: 2.5vw;
                max-width: 80%;
            }
        }

        .vpCard {
            margin-top: 2vh;
        }

        .pageContent {
            margin-top: 2em;
        }
    }
}

@media (max-width: 1000px) and (orientation: portrait) {
    .allocationStaking {
        margin-top: -80px;
        .pageTitle {
            flex-direction: column;
            .mainText {
                color: white;
                font-size: 19pt;
                max-width: 100%;
                margin-top: 2em;
            }

            .infoButton {
                color: white;
                border-color: white;
                width: fit-content;
                padding-left: 3em;
                padding-right: 3em;
                font-size: 14pt;
                line-height: 14pt;
                height: auto;
                padding-top: 0.5em;
                padding-bottom: 0.5em;
            }
        }

        .vpCard {
            margin-top: 5vh;
        }

        .pageContent {
            margin-top: 1em;

            .infoCards {
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }

            .stakingCards {
                display: flex;
                width: 100%;
                margin-bottom: 3em;
                justify-content: space-between;
                flex-direction: column;
            }
        }
    }
}
