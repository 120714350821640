.Table {
    overflow-x: visible;
    background: #ffffff;
    box-shadow: 0px -2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    position: relative;

    .invisibleButtonRow {
        position: relative;

        .headerButtons {
            position: absolute;
            right: -1rem;
            top: -1.25rem;
            gap: 1rem;

            display: flex;

            justify-content: flex-end;

            .switchNetworksButton {
                background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
                border-radius: 4px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 500;
                font-size: 1rem;
                color: #ffffff;
                border: 0px;
                padding: 0.75em 1.5em 0.75em 1.5em;
                cursor: pointer;
            }

            .claimAllButton,
            .disabledClaimAllButton {
                background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
                border-radius: 4px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 500;
                font-size: 1rem;
                color: #ffffff;
                border: 0px;
                padding: 0.75em 1.5em 0.75em 1.5em;
                cursor: pointer;
            }

            .disabledClaimAllButton {
                filter: grayscale(1) brightness(1.2);
            }
        }
    }

    .line {
        background: #000000;
        opacity: 0.1;
        border-radius: 4px;
        height: 2px;
    }

    .emptyMessage {
        width: 100%;
        text-align: center;
        font-weight: 400;
        color: rgb(192, 192, 192);
        min-height: 2em;
    }

    .polygonNetwork {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        .switchNetworksButton {
            background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
            border-radius: 4px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            color: #ffffff;
            border: 0px;
            padding: 0.75em 1.5em 0.75em 1.5em;
            cursor: pointer;
        }
    }
}
