.UsefulLinks{
    display: flex;
    justify-content: space-between;

    .fancyLinks{
        .logo{
            height: 35px;
            margin-top: 14px;;
        }

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .buttons{
            display: flex;
            flex-direction: column;

            a{
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
                text-decoration: none;
                display: inline-block;
                font-family: "Gothic A1", sans-serif;
                font-weight: 600;
                color: #fff;
                text-align: center;
                vertical-align: middle;
                cursor: pointer;
                user-select: none;
                background-color: transparent;
                border: 1px solid transparent;
                padding: 0.5rem 0.75rem;
                font-size: .9rem;
                border-radius: 0.375rem;
                transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            
                background-color: #6e84a3;
                border-color: #6e84a3;
                border-radius: 20px;
                margin-top: 0.65em;
                letter-spacing: 0.02em;

                img{
                    height: 24px;
                    padding-right: 0.5em;
                }

                .content{
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    div{
                        height: fit-content;
                    }
                }
                transition: all 0.3s ease;
            }

            a:hover{
                background-color: #5a6d86;
                border-color: #5a6d86;
                transform: translateY(-0.15em);
            }
        }
    }

    .genericLinks{
        display: flex;
        width: 50%;
        max-width: 600px;
        justify-content: space-between;

        .linksBlock{
            flex-direction: column;
            

            h6{
                letter-spacing: .08em;
                color: #6e84a3!important;
                text-transform: uppercase;
                font-size: .625rem;
                margin-bottom: 0.7em;
            }

            .links{
                display: flex;
                flex-direction: column;

                a{
                    text-decoration: none;
                    color: white;
                    padding-left: 0!important;
                    padding: 0.5rem 1rem;
                    letter-spacing: 0.1em !important;
                    line-height: 1.5em;

                    font-family: "Gothic A1",sans-serif;
                    font-size: .85rem;
                    font-weight: 500;
                    text-align: left;
                    letter-spacing: 0.03em;
                }

                a:hover{
                    color: rgb(204, 204, 204);
                }
            }
        }

        .linksBlock:last-child{
            margin-right: 0;
        }
    }

    
}

@media(max-width: 900px) and (orientation: portrait){
    .UsefulLinks{
        flex-direction: column;

        .genericLinks{
            width: 100%;
        }
    }
}