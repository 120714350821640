.formPanel {
    background-color: white;
    border-radius: 10px;
    padding: 1em;
    width: 90%;
    box-shadow: 0px 0px 10px rgba(139, 139, 139, 0.452);

    h1 {
        font-family: "Cabin", sans-serif;
        color: rgb(92, 92, 92);
        margin-top: 15px;
    }

    .formRow {
        display: flex;
        margin-bottom: 1em;
        margin-top: 1em;
        align-items: center;
    }

    .previewSection {
        .previews {
            .preview {

                .projectDetailsPreviewHeader{
                    display: flex;
                    align-items: center;

                    .deviceSelector{
                        margin-left: 1em;
                    }
                }
                h2 {
                    color: rgb(92, 92, 92);
                }

                .detailsPreview {
                    height: 65vh;
                    width: 100%;
                    .previewIframe {
                        max-width: 100%;
                        height: 100%;
                        position: absolute;
                        
                        -ms-transform: scale(0.6);
                        -moz-transform: scale(0.6);
                        -o-transform: scale(0.6);
                        -webkit-transform: scale(0.6);
                        transform: scale(0.6);

                        -ms-transform-origin: 0 0;
                        -moz-transform-origin: 0 0;
                        -o-transform-origin: 0 0;
                        -webkit-transform-origin: 0 0;
                        transform-origin: 0 0;

                    }
                }
            }
        }
    }

    hr {
        width: 100%;
    }
}
