.idos {
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
    margin-bottom: 40px;

    .emptyArrays {
        color: rgb(163, 163, 163);
        display: flex;
        justify-content: center;
        font-size: 20pt;
        width: 100%;
    }
}

.upidos {
    display: flex;
    align-items: stretch !important;
    flex-direction: row;
    justify-content: flex-start !important;

    flex-wrap: wrap;
    margin-bottom: 40px;

    .emptyArrays {
        color: rgb(163, 163, 163);
        display: flex;
        justify-content: center;
        font-size: 20pt;
        width: 100%;

        .connectPrompt {
            display: flex;
            flex-direction: column;
            align-items: center;
            .connectButton {
                width: 180px;
                height: 52px;
                left: 1458px;
                top: 36px;
                background: rgb(52, 123, 255);
                border-radius: 10px;
                border-color: #ffffff;
                border-width: 0px;
                font-family: "Monseratt", sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 1.1rem;
                line-height: 22px;
                /* identical to box height */
                cursor: pointer;
                color: #000000;
                transition: 0.5s;
                color: white;
                box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.274);
            }

            .connectButton:hover {
                background: rgb(85, 144, 252);
            }
        }
    }
}

.ongoing {
    margin-top: 4em;
    .title {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 28pt;
        line-height: 49px;
        color: #000000 !important;
    }
    .ongoingIdos {
        width: 100%;

        .emptyArrays {
            color: rgb(163, 163, 163);
            display: flex;
            justify-content: center;
            font-size: 20pt;
        }
    }
}

.menu {
    display: flex;
    margin-top: 136px;
    margin-bottom: 36px;

    .menuElement {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 49px;
        color: #000000;
        opacity: 0.3;
        margin-right: 64px;
        cursor: pointer;
    }

    .menuElementActive {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 49px;
        color: #000000;
        margin-right: 64px;
        cursor: pointer;
    }

    .line {
        width: 100%;
        height: 4px;
        background: #000000;
        margin-top: 10px;
    }
}

@media (min-width: 350px) {
    .menu {
        flex-direction: column;
        .menuElement {
            font-size: 24px;
        }
        .menuElementActive {
            font-size: 24px;
        }
    }
}

@media (min-width: 850px) {
    .menu {
        flex-direction: row;
        .menuElement {
            font-size: 40px;
        }
        .menuElementActive {
            font-size: 40px;
        }
    }
}

@media (max-width: 1000px) and (orientation: portrait) {
    .menu {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .upidos {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 40px;
        flex-direction: column;

        .emptyArrays {
            color: rgb(163, 163, 163);
            display: flex;
            justify-content: center;
            font-size: 20pt;
            width: 100%;
        }
    }
}
