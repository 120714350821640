.switchContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    h1 {
        font-size: 1.4rem;
        line-height: 1rem;
        height: 1rem;
        margin-right: 0;
        opacity: 0.5;
        font-weight: 400;
        transition: all 0.3s;
        font-family: "Montserrat", sans-serif;
    }

    .selectedVersion {
        opacity: 1;
        font-weight: 600;
        font-size: 1.5rem;
    }
}
