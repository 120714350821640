.IdoBlock {
    // width: calc(50% - 108px);
    width: 35vw;

    margin-bottom: 36px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 2em;
    padding-right: 2em;
    height: fit-content;
    position: relative;

    .privateSaleFlag {
        font-weight: 600;
        position: absolute;
        top: -1em;
        right: -1em;
        font-family: "Montserrat";
        color: white;
        background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
        padding: 0.5em 1em 0.5em 1em;
        font-size: calc(12px + 0.2vw);
        border-radius: 4px 4px 4px 4px;
    }

    .notWhitelisted {
        font-weight: bold;
        font-size: 1.5em;
    }

    .tokenBlock {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-start;

        .token {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            .tokenLogo {
                margin-right: 0.8em;
                max-height: 96px;
                width: auto;
                max-width: 30%;
                border-radius: 16px;
            }

            .text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin-left: 5px;
                width: 100%;

                .symbol {
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 32px;
                    line-height: 39px;
                    color: #000000;
                    font-weight: 600;
                    margin-bottom: 0.2em;
                }

                .name {
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: #000000;
                }

                .media {
                    margin-top: 0em;
                    margin-bottom: 0;
                    display: flex;

                    .verticalSeparator {
                        border-left: 1px solid gray;
                        margin-right: 0.3em;
                    }

                    a {
                        margin-right: 0.3em;
                        text-decoration: none;
                        cursor: pointer;
                        display: flex;
                        align-items: center;

                        img {
                            background: rgb(255, 255, 255) !important;
                            border-radius: 50%;
                            height: 1.5em;
                            width: auto;
                            filter: opacity(0.6);
                            transition: all 0.5s;

                            &:hover {
                                filter: none;
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }

        .progresLabel {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: -36px;
            .styledLabel {
                background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
                border-radius: 4px 0px 0px 4px;
                padding: 4px 7px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #ffffff;
            }
        }
    }

    .saleInfo {
        .line {
            height: 1.5px;
            width: 100%;
            background: #000000;
            opacity: 0.1;
            margin-bottom: 1em;
            margin-top: 0.5em;
        }
    }

    .actions {
        .line {
            height: 1px;
            width: 100%;
            background: #000000;
            opacity: 0.1;
            margin-bottom: 1em;
            margin-top: 0.5em;
        }

        .lotteryWinner {
            font-family: Montserrat;
            text-align: center;
        }

        .actionBlock {
            margin-top: 1em;
            display: flex;
            flex-direction: column;
            align-items: center;

            .kyc {
                p {
                    font-family: Montserrat;
                    font-size: 1.1em;
                    font-weight: 500;
                    opacity: 0.6;
                    margin: 0;
                    padding: 0;
                }

                a {
                    display: inline-block;
                    color: #0aa7f5;
                    margin-left: 0.3em;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            .addToken {
                height: 100% !important;
                width: 100%;
                button {
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1em;
                    color: #000000;

                    padding: 0.5em;
                    //background: white;
                    border: none;
                    color: white;
                    border-radius: 1.5rem;
                    //box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.117);
                    background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
                    font-weight: 600;
                    width: 15em;
                    cursor: pointer;
                    min-height: 35pt;
                }
            }

            .text {
                font-size: 11pt;
            }

            .buttonBlock {
                width: 100%;
                display: flex;
                justify-content: flex-end;

                .whitelistEnd {
                    font-weight: 500;
                    font-size: 1rem;
                }

                .ownerDepositWrapper {
                    margin-right: -60px;
                    display: flex;
                    position: relative;

                    label {
                        position: absolute;
                        right: 5em;
                        // color: white;
                        font-family: "Monseratt", sans-serif;
                        font-weight: 600;
                        top: 50%;
                        transform: translateY(-51%);
                        font-size: 100%;
                    }

                    .inputField {
                        background: rgba(0, 0, 0, 0.09);
                        border-radius: 35pt;
                        border-width: 0px;
                        // color: white;
                        border: none;
                        height: 35pt;
                        font-size: 14pt;
                        font-weight: 900;
                        padding-left: 1.5em;
                        width: 100%;

                        font-family: "Montserrat", sans-serif;
                        outline: none;
                        padding-top: 0;
                        padding-bottom: 0;
                        z-index: 0;
                        position: relative;
                    }

                    .inputField:focus {
                        outline: none;
                        border: none;
                    }

                    .inputFieldWrapperDIV {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        // color: white;
                        margin-right: 5px;
                    }

                    .max {
                        // color: white;
                        position: absolute;
                        right: 70px;
                        top: calc(60% - 12pt) !important;

                        font-size: 12pt !important;
                        border-radius: 8px 8px 0px 0px;
                        font-weight: 700;
                        cursor: pointer;
                        transition: all 1s ease-out;
                    }

                    .max:hover {
                        transform: scale(1.1);
                    }
                }

                .depositWarning {
                    margin-top: 0;
                    color: rgb(255, 197, 197);
                    background-color: rgba(255, 255, 255, 0.278);
                    width: fit-content;
                    margin-left: 1em;
                    font-family: "Montserrat", sans-serif;
                    font-weight: 600;
                    padding: 10px;
                }

                .tierIndicator {
                    font-family: "Monseratt", sans-serif;
                }

                .inputs {
                    display: flex;

                    border-radius: 35pt;
                    .inputFieldWrapper {
                        margin-right: -60px;
                        display: flex;
                        position: relative;

                        label {
                            position: absolute;
                            right: 5em;
                            // color: white;
                            font-family: "Monseratt", sans-serif;
                            font-weight: 600;
                            top: 50%;
                            transform: translateY(-51%);
                            font-size: 100%;
                        }

                        .inputField {
                            background: rgba(0, 0, 0, 0.09);
                            border-radius: 35pt;
                            border-width: 0px;
                            // color: white;
                            border: none;
                            height: 35pt;
                            font-size: 14pt;
                            font-weight: 900;
                            padding-left: 1.5em;
                            width: 100%;

                            font-family: "Montserrat", sans-serif;
                            outline: none;
                            padding-top: 0;
                            padding-bottom: 0;
                            z-index: 0;
                            position: relative;
                        }

                        .inputField:focus {
                            outline: none;
                            border: none;
                        }

                        .inputFieldWrapperDIV {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            // color: white;
                            margin-right: 5px;
                        }

                        .max {
                            // color: white;
                            position: absolute;
                            right: 70px;
                            top: calc(60% - 12pt) !important;

                            font-size: 12pt !important;
                            border-radius: 8px 8px 0px 0px;
                            font-weight: 700;
                            cursor: pointer;
                            transition: all 1s ease-out;
                        }

                        .max:hover {
                            transform: scale(1.1);
                        }
                    }
                }

                button {
                    z-index: 1;
                    min-width: 10em;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    width: fit-content;
                    height: auto;
                    min-height: 35pt;
                    border-radius: 35pt;
                    border-width: 0px;
                    font-family: Montserrat;
                    color: white;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 100%;
                    box-shadow: 0px 0px 16px rgba(128, 128, 128, 0.164);

                    // color: #ffffff;

                    background: #23befb;
                    border: none;
                    /* identical to box height */
                    cursor: pointer;
                    transition: all 0.5s ease-out;
                }

                button:hover {
                    box-shadow: 0px 0px 10px rgba(128, 128, 128, 0.067);
                }

                button:disabled {
                    background: rgb(52, 195, 252);
                    // color: white;
                    cursor: default;
                    box-shadow: none;
                }
            }

            .mediaMobile {
                display: flex;
                flex-wrap: nowrap;
                a {
                    margin-right: 10px;
                    text-decoration: none;
                    img {
                        background: rgb(255, 255, 255) !important;
                        border-radius: 28px;
                        height: 28px;
                        width: auto;
                    }
                }
            }
        }

        .additionalSaleInfo {
            margin-top: 1rem;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: end;
            gap: 1rem;
            row-gap: 0.25rem;
            font-family: Montserrat;
            text-align: end;

            .blackSquare {
                height: 0.25rem;
                width: 0.25rem;
                background-color: black;
                margin-right: -0.25rem;
                margin-left: -0.25rem;
            }

            .colorInsert {
                font-weight: 600;
                color: #0aa7f5;
                margin-left: 0.25rem;
            }
        }

        .tierInfo {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .infoItem {
                text-align: left;

                h1 {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1em;
                    color: black;
                    margin: 0;
                    height: auto;
                }

                h2 {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.5em;
                    color: #000000;
                    height: auto;
                    margin: 0;
                }
            }

            .fancyInfoItem {
                text-align: right;

                h1 {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1em;
                    color: #0aa7f5;
                    font-weight: 600;
                    margin: 0;
                    height: auto;

                    display: inline-flex;
                    flex-direction: row;
                    align-items: center;

                    img {
                        height: 1em;
                        width: 1em;
                        margin-left: 0.2em;
                    }
                }

                h2 {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.5em;
                    color: #000000;
                    height: auto;
                    margin: 0;
                }
            }
        }
    }

    .slogan {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 1em;
        line-height: 1.5em;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        transition: all 1s;

        &:disabled {
            -webkit-line-clamp: 5 !important;
            color: red;
        }

        .readMore {
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: white;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 1em;

            text-decoration-line: underline;
            margin-left: 0.25em;
            cursor: pointer;
            box-shadow: -20px 0px 10px white;
            display: flex;
            align-items: center;
        }
    }

    .expandedSlogan {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 1em;
        line-height: 1.5em;

        display: -webkit-box;
        -webkit-line-clamp: none;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        transition: all 1s;
        .readMore {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 1em;
            cursor: pointer;
            text-decoration: underline;
            display: flex;
            align-items: center;
        }
    }
}

.totalRaised {
    margin-top: 5px;
    .text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 20px;
        color: #000000;
        margin: 10px 0px;
    }

    .count {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 39px;
        background: -webkit-linear-gradient(#0aa7f5, #3ce7ff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.textToShowBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 25px;
    .textToShow {
        display: flex;
        flex-direction: column;
        .text {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 22px;
            line-height: 20px;
            color: #000000;
        }
        .value {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
        }
    }
}

.launchDetaid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 39px;
    .block {
        display: flex;
        flex-direction: column;
    }

    .subBlock {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
    }

    .text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
        margin-right: 10px;
    }

    .value {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 15px;
        color: #0495ce;
        display: flex;
        flex-direction: column;
        justify-content: end;
    }
}

.priceDetail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    .price {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        text-align: right;
        color: #000000;
    }

    .text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: right;
        color: #000000;
    }
}

.roundDetail {
    .block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .text {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            text-align: right;
            color: #000000;
        }

        .roundInfo {
            font-family: "Montserrat", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 1.25vw;
            line-height: 29px;
            color: #000000;
        }

        .timeInfo {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 1.25vw;
            line-height: 29px;
            text-align: right;
            background: -webkit-linear-gradient(#0aa7f5, #3ce7ff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}
.progressBarWrapper {
    margin-bottom: 1.5em;
    .progressBar {
        position: relative;
        height: 30px;
        margin-top: 20px;
        .backPart {
            background: #000000;
            opacity: 0.1;
            border-radius: 4px;
            width: 100%;
            height: 24px;
            position: absolute;
        }

        .topPart {
            background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
            border-radius: 4px;
            height: 24px;
            position: absolute;
            max-width: 100%;
        }
    }

    p {
        margin: 0;
        font-size: 8pt;
        font-family: "Cabin", sans-serif;
    }
}

@media (min-width: 850px) {
    .IdoBlock {
    }
    .tokenBlock {
        flex-direction: row;
        .token {
            flex-direction: row;
            img {
                width: auto;
                height: auto;
                margin: auto;
            }
            .name {
                margin: 0;
            }
            .text {
                text-align: center;
                margin: 0px;
            }
        }

        .priceDetail {
            .price {
                text-align: left;
            }

            .text {
                text-align: left;
            }
        }
    }
}

@media (min-width: 1280px) {
    .IdoBlock {
        width: calc(50% - 108px);
    }
}

@media (max-width: 1440px) {
    .IdoBlock {
        width: calc(63% - 108px);
        .tokenBlock {
            .token {
                .text {
                    margin-left: 0;

                    .tokenLogo {
                        max-height: 4em;
                    }

                    .name {
                        font-size: 10pt;
                    }

                    .symbol {
                        font-size: 16pt;
                        line-height: 16pt;
                    }
                }
            }
        }
    }

    .saleInfo {
        .line {
            margin-bottom: 0.5em;
            margin-top: 0.5em;
        }
    }

    .priceDetail {
        .price {
            font-size: 16pt;
            line-height: 14pt;
        }

        .text {
            font-size: 12pt;
            line-height: 14pt;
        }
    }

    .roundDetail {
        .block {
            .text {
                font-size: 10pt;
                line-height: 10pt;
            }

            .roundInfo {
                font-size: 12pt;
                line-height: 12pt;
            }

            .timeInfo {
                font-size: 12pt;
                line-height: 12pt;
            }
        }
    }

    .progressBarWrapper {
        margin-bottom: 0.5em;
        .progressBar {
            position: relative;
            height: 20px;
            margin-top: 10px;
            .backPart {
                background: #000000;
                opacity: 0.1;
                border-radius: 4px;
                width: 100%;
                height: 18px;
                position: absolute;
            }

            .topPart {
                background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
                border-radius: 4px;
                height: 18px;
                position: absolute;
                max-width: 100%;
            }
        }

        p {
            margin: 0;
            font-size: 6pt;
            font-family: "Cabin", sans-serif;
        }
    }
}

@media (max-width: 1000px) and (orientation: portrait) {
    .IdoBlock {
        width: auto;
        padding: 1em !important;

        .tokenBlock {
            flex-direction: row;
            .token {
                display: flex;
                flex-direction: row;
                .tokenLogo {
                    margin-left: -5px;
                    height: auto !important;
                }

                .text {
                    justify-content: center;

                    .symbol {
                        line-height: 16pt;
                        font-size: 16pt;
                    }

                    .name {
                        line-height: 8pt;
                    }
                }
            }
        }

        .saleInfo {
            .line {
                margin-top: 1em;
                margin-bottom: 1em;
            }

            .roundDetail {
                .block {
                    .timeInfo {
                        font-size: 10pt;
                    }

                    .roundInfo {
                        font-size: 10pt;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .IdoBlock {
        margin-top: 20px;
    }
}
