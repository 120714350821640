.stakeCard {
    margin-bottom: 3em;
    .cardHeader {
        display: flex;

        align-items: center;

        .headerIcon {
            height: 100%;
            margin-right: 1em;
            box-shadow: 0px 8px 16px rgb(226, 226, 226);
            border-radius: 28pt;
        }

        .headerText {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 28pt;
            line-height: 39px;
            color: black !important;

            display: inline-flex;
            align-items: center;
            .headerInfoIcon {
                color: rgba(0, 0, 0, 0.2);
                font-size: 0.7em;
                margin-left: 0.5em;
            }
        }
        margin-bottom: 1.5em;
    }

    .cardContent {
        background: #ffffff;
        /* Block */

        padding: 2em;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
        border-radius: 4px;

        .input {
            margin-bottom: 0.5em;
            .inputFields {
                display: flex;

                .inputField {
                    background: rgba(0, 0, 0, 0.05);
                    border: none;
                    border-radius: 4px 0px 0px 4px;
                    color: black;
                    height: 3em;
                    font-size: 14pt;
                    padding-left: 1.5em;
                    width: 80%;
                }

                .inputFieldPostpend {
                    background: rgba(0, 0, 0, 0.05);
                    border: none;
                    border-radius: 0px 4px 4px 0px;
                    color: black;
                    text-align: center;

                    font-size: 14pt;
                    height: 3em;
                    width: 20%;
                }
            }

            .inputHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .headerBalance {
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12pt;
                    line-height: 36pt;
                }

                .headerMax {
                    background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
                    border-radius: 4px;
                    border: none;

                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 8pt;

                    width: 48px;
                    height: 15pt;

                    color: #ffffff;
                    cursor: pointer;
                }
            }
        }

        .percentSlider {
            margin-top: 1em;
            margin-bottom: 1em;
        }

        .confirmationButton {
            .stakeButton {
                background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
                border-radius: 4px;
                color: white;
                border: none;
                height: 3em;
                width: 100%;
                font-size: 12pt;
                font-family: "Montserrat", sans-serif;
                cursor: pointer;
                transition: 0.3s;

                &:disabled {
                        filter: grayscale(1);
                    }
            }

            .stakeButton:hover {
                box-shadow: 0px 0px 10px rgba(0, 119, 255, 0.452);
            }
        }
    }
}

@media (max-width: 820px) {
    .stakeCard {
        .cardHeader {
            display: flex;

            align-items: center;

            .headerIcon {
                height: 100%;
                margin-right: 1em;
            }

            .headerText {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 21pt;
                line-height: 21pt;
            }
            margin-bottom: 0.5em;
        }

        .cardContent {
            padding: 1em;

            .input {
            }
        }
    }
}

@media (max-width: 1440px) {
    .stakeCard {
        .cardHeader {
            display: flex;
            height: 28pt;

            align-items: center;
            margin-bottom: 1em;

            .headerIcon {
                height: 24pt;
                margin-right: 1em;
                box-shadow: 0px 8px 16px rgb(226, 226, 226);
                border-radius: 28pt;
            }

            .headerText {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 22pt;
                line-height: 28pt;
            }
        }
    }
}

@media (max-width: 1000px) and (orientation: portrait) {
    .stakeCard {
        width: 100%;
        margin-bottom: 2em;

        .cardHeader {
            .headerIcon {
                box-shadow: none;
            }
            .headerText {
                font-size: 20pt;
                color: white;
            }
            margin-bottom: 0.5em;
        }

        .cardContent {
            box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
        }
    }
}
