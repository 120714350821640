.TableRow {
    display: flex;
    padding: 24px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    div {
        display: block;
    }
    .infoBlock {
        display: flex;

        img {
            width: 32px;
            height: 32px;
            margin-right: 8px;
        }

        .name {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
        }

        .symbol {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
        }
    }

    .divUpdate {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .claimability{
            filter: grayscale(1);
            opacity: 1;
        }
        .claimability,
        .claimed {
            color:rgb(4, 138, 205);
            background: rgb(4, 138, 205);
            background: linear-gradient(133deg, rgba(4, 138, 205, 1) 0%, rgba(60, 231, 255, 1) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 1rem;
            font-family: "Montserrat";
            font-weight: 500;
        }

        .claimed {
            filter: grayscale(1);
            opacity: 0.5;
        }
    }
}

@media (max-width: 1440px) {
    .TableRow {
        font-size: 10pt;
    }
}

@media (max-width: 1000px) and (orientation: portrait) {
    .TableRow {
        min-width: 700px;
    }
}
