$button-width: 12em;


.ActiveButton{
    width: 200px;
    height: 56px;
    background: #FFFFFF;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 4px 4px 0px 0px;
    background: -webkit-linear-gradient(#0AA7F5, #3CE7FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-right: 16px;
    border: 0px;
    cursor: pointer;
}

.Button{
    width: 200px;
    height: 56px;
    background: #F2F2F2;
    border-radius: 4px 4px 0px 0px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    opacity: 0.5;
    margin-right: 16px;
    border: 0px;
    cursor: pointer;
}

@media(min-width:350px) {
    .ActiveButton{
        width: 100%;
        margin-top: 10px;
        border-radius: 10px 10px 0px 0px;
    }

    .Button{
        width: 100%;
        margin-top: 10px;
        border-radius: 10px 10px 0px 0px;
    }
}

@media(min-width:850px) {
    .ActiveButton{
        width: 200px;
    }

    .Button{
        width: 200px;
    }
}

@media (max-width: 1440px){
    .Button{
        font-size: 10pt;
        width: $button-width;
        height: 3.5em;
        border-radius: 2px;
    }

    .ActiveButton{
        font-size: 10pt;
        width: $button-width;
        height: 3.5em;
        border-radius: 2px;
        z-index: 100;
    }
}

@media (max-width: 1000px) and (orientation: portrait){
    .ActiveButton{
        max-width: 10em;
    }
    
    .Button{
        max-width: 10em;
    }
}