$font-global: "Monseratt", sans-serif;
$button-background-color: rgb(52, 123, 255);

.Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 2.6vh;

    .logo {
        cursor: pointer;
        img {
            max-width: 12vw;
            -webkit-filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5));
            filter: drop-shadow(0px 0px 10px rgba(253, 253, 253, 0.5));
            transition: all 1s;
        }

        img:hover {
            -webkit-filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 1));
            filter: drop-shadow(0px 0px 20px rgba(253, 253, 253, 1));
        }
    }

    .connectButton {
        width: 180px;
        height: 52px;
        left: 1458px;
        top: 36px;
        background: $button-background-color;
        border-radius: 10px;
        border-color: #ffffff;
        border-width: 0px;
        font-family: $font-global;
        font-style: normal;
        font-weight: 600;
        font-size: 1.1rem;
        line-height: 22px;
        /* identical to box height */
        cursor: pointer;
        color: #000000;
        transition: 0.5s;
        color: white;
        box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.274);
    }

    .connectButton:hover {
        background: rgb(85, 144, 252);
    }

    .connectedButton {
        font-size: 1.1em;
        background: rgba(52, 123, 255, 0);
        display: flex;
        align-items: center;
        border: 2px solid white;
        transition: 1s all ease-out;

        height: 3em;
        color: white;
        font-family: $font-global;

        border-radius: 3em;

        .balanceDiv {
            color: rgb(245, 245, 245);
            height: 100%;
            border: 3px solid rgba(0, 0, 0, 0);
            border-radius: 10px;
            border-right: 0px;
            padding-left: 1em;
            display: flex;
            align-items: center;
            padding-right: 1em;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        .splitter {
            width: 1px;
            background-color: rgba(255, 255, 255, 0.479);
            height: 50%;
        }

        .addressDiv {
            color: rgb(255, 255, 255);

            height: 3em;
            border: 2px solid rgb(255, 255, 255);
            border-radius: 3em;
            border-right: 0px;
            border-left: 0px;
            border-bottom-left-radius: 0 !important;
            border-top-left-radius: 0px;
            padding-left: 1em;

            display: flex;
            align-items: center;
            transition: 1s;

            .personIconDiv {
                margin-left: 0.5em;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3em;
                border: 0px;
                height: 3em;
                width: 3em;

                .personIcon {
                    height: 1em;
                }
            }
        }

        &:hover {
            box-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
            clip-path: inset(-15px -15px -15px 0px);
            overflow: visible !important;
        }
    }
}

.button {
    display: none;

    .buttonWeb {
        display: flex;
        align-items: center;

        .popoverContainer {
            .stakingContractVersion {
                background-color: red !important;
                padding: 1rem;
                display: flex;
                flex-direction: column !important;

                .v1Button {
                    background-color: orange;
                }
            }
        }

        .winPeak {
            background-color: gold;
            font-weight: 900;
            font-family: "Montserrat";
            text-align: center;
            vertical-align: center;
            width: auto;
            padding-left: 0.75em;
            padding-right: 0.75em;
            height: 2.5em;
            position: relative;

            border-radius: 10px;
            border-width: 0px;
            font-family: "Cabin", sans-serif;
            font-style: normal;
            font-size: 14pt;

            /* identical to box height */
            cursor: pointer;
            color: black;
            margin-right: 0.5em;
            transition: 0.5s all ease-out;
        }

        .winPeak:active {
            transform: scale(0.5);
            filter: brightness(0.9);
        }

        .winPeak:hover {
            transform: translateY(-3px);
        }

        .applyForIdo {
            background-color: transparent;
            font-weight: 400;
            text-align: center;
            vertical-align: center;
            width: auto;
            padding-left: 0.6em;
            padding-right: 0.6em;
            height: 2em;
            position: relative;

            border-radius: 10px;
            border-width: 0px;
            font-family: "Cabin", sans-serif;
            font-style: normal;
            font-size: 14pt;

            /* identical to box height */
            cursor: pointer;
            color: #000000;
            color: white;
            margin-right: 0.5em;
        }

        .applyForIdo:after {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: #ffffff;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
        }

        .applyForIdo:hover:after {
            transform: scaleX(1);
            transform-origin: bottom left;
        }
    }
}

.buttonMobile {
    display: none;
    .iconMobile {
        background-color: white;
        border-radius: 100%;
        padding: 6px;
        color: black;
    }
}
.showMobileMenu {
    display: none;
}

.drawerCloseIconDiv {
    background-color: white;
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    padding-bottom: 0em;
}

.MobileDrawer {
    background: white;
    border-radius: 0 0 0 100vw;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-left: 6em;

    .drawerContent {
        h1 {
            margin: 0;
            font-size: 20pt;
            margin-bottom: 0;
            font-family: "Montserrat";
            font-weight: 900;
            height: 60px;
        }

        hr {
            border-color: rgba(202, 202, 202, 0.116);
        }

        .mobileAccount {
            display: flex;
            margin-top: 0.5em;
            align-items: stretch;

            .accountIcon {
                margin-right: 0.5em;
            }

            .userInfo {
                height: auto;
                font-family: "Montserrat";
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                div {
                    font-size: 11pt;
                }

                .balanceDiv {
                    font-weight: 600;
                }
            }
        }

        .mobileConnectWallet {
            background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
            border-radius: 50px;
            border: none;
            color: white;
            font-family: "Montserrat";
            font-weight: 600;
            font-size: 12pt;
            height: 2.7em;
            padding-left: 1.5em;
            padding-right: 1.5em;
        }
    }
}
.mobileMenu {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;

    .menuBlank {
        width: 30%;
        background: #000000;
        opacity: 0.3;
    }
    .menuElements {
        background-color: #ffffff;
        width: 70%;
        text-align: center;
        .title {
            font-family: $font-global;
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 48px;
            text-align: center;
            background: -webkit-linear-gradient(#0aa7f5, #3ce7ff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 30px;
        }

        .menuElement {
            font-family: $font-global;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 48px;
            text-align: center;
            margin-bottom: 30px;
        }
    }
}

.hideMenu {
    display: none;
}

@media (min-width: 350px) {
    .showMobileMenu {
        display: block;
    }
    .buttonMobile {
        display: block;
        img {
            display: block;
            height: 24px;
            width: 24px;
        }
    }
    .button {
        display: none;
        margin-top: 16px;
    }
}

@media (min-width: 840px) {
    .showMobileMenu {
        display: none;
    }

    .button {
        display: block;
    }
    .buttonMobile {
        display: none;
        img {
            display: none;
            height: 24px;
            width: 24px;
        }
    }
}

.kyc {
    min-height: 2em;

    width: 100%;

    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.363);
    transition: 1s;

    .text {
        margin-right: 10px;
        font-family: $font-global;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: white;
        display: flex;
        margin: auto;
        align-items: center;
    }

    .greentext {
        margin-right: 10px;
        font-family: $font-global;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: rgb(211, 254, 236);
        display: flex;
        margin: auto;
        align-items: center;
    }

    .closeButton {
        margin-right: 0;
    }
    button {
        text-decoration: underline;
        background-color: transparent;
        border: none;
        font-family: $font-global;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: white;
        cursor: pointer;
    }
}

.hide {
    display: none;
}

@media (max-width: 1440px) {
    .button {
        .buttonWeb {
            .applyForIdo {
                height: 2.5em;
                font-size: 1rem;
            }

            .connectButton {
                height: 2.5em;
                width: auto;
                padding-left: 2em;
                padding-right: 2em;
            }
        }
    }

    .Header {
        .connectedButton {
            font-size: 1em;
            background: rgba(52, 123, 255, 0);
            display: flex;
            align-items: center;
            border: 2px solid white;

            height: 2.5em;
            color: white;
            font-family: $font-global;

            border-radius: 2.5em;

            .balanceDiv {
                color: rgb(245, 245, 245);
                height: 100%;

                border-right: 0px;
                padding-left: 1em;
                display: flex;
                align-items: center;
                padding-right: 1em;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }

            .splitter {
                width: 1px;
                background-color: rgba(255, 255, 255, 0.479);
                height: 50%;
            }

            .addressDiv {
                color: rgb(255, 255, 255);

                height: 2.5em;
                border: 2px solid rgb(255, 255, 255);
                border-radius: 3em;
                border-right: 0px;
                border-left: 0px;
                border-bottom-left-radius: 0px;
                border-top-left-radius: 0px;
                padding-left: 1em;

                display: flex;
                align-items: center;
                cursor: pointer;
                transition: 1s;

                .personIconDiv {
                    margin-left: 0.5em;
                    background-color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 2.5em;
                    border: 0px;
                    height: 2.5em;
                    width: 2.5em;

                    .personIcon {
                        height: 0.9em;
                    }
                }
            }
        }
    }
}

@media (max-width: 1000px) and (orientation: portrait) {
    .Header {
        padding: 0;
        margin-bottom: 2em;
        .logo {
            img {
                max-width: 40vw;
                width: 40vw;
            }
        }
    }
}
