.TableRow{
    display: flex;
    padding: 24px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    div{
        display: block;
    }
    .infoBlock{
        display: flex;

        img{
            width: 32px;
            height: 32px;
            margin-right: 8px;
        }

        .name{
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
        }

        .symbol{
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
        }
    }

    .divUpdate{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}