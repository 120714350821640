.TableHeader{
    display: flex;
    flex-direction: row;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 17px;
    color: #000000;
    padding: 24px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    .claimAllButton {
            background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
            border-radius: 4px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            color: #ffffff;
            border: 0px;
            padding: 0.75em 1.5em 0.75em 1.5em;
            cursor: pointer;
            margin-left: -5em;
        }
}

@media (max-width: 1440px){
    .TableHeader{
        font-size: 11pt;
    }
}

@media(max-width: 1000px) and (orientation: portrait){
    .TableHeader{
        min-width: 700px;
    }
}