.TableRow{
    display: flex;
    padding: 8px;
    padding-left: 14px;
    padding-right: 14px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 17px;
    color: #000000;
    cursor: pointer;
   
    div{
        display: block;
    }
    .infoBlock{
        display: flex;

        img{
            width: 32px;
            height: 32px;
            margin-right: 8px;
        }

        .name{
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
        }

        .symbol{
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
        }
    }

    .divUpdate{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media (max-width: 1440px){
    .TableRow{
        font-size: 8pt;
        .infoBlock{

            img{
                height: 24px;
                width: 24px;
            }

            .name{
                font-size: 8pt;;
            }

            .symbol{
                font-size: 8pt;
            }


        }
    }
}

@media (min-width: 1500px) {
    .TableRow{
        font-size: 12pt;
    }
}