.idoDetail {
    margin-bottom: 3em;

    .subscribeSection {
    }

    .firstBlock {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 60px;
        margin-bottom: 10em;
    }

    .participateBlocks {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin: 80px 0px;

        .participateBlock {
            width: fit-content;
            margin: 0;

            min-width: calc(100% / 5);
        }

        .title {
            font-family: Montserrat;
            text-align: center;
            font-style: normal;
            font-weight: 600;
            font-size: 16pt;
            line-height: 16pt;
            margin-bottom: 15px;
            color: #000000;
        }

        .participateBlock:first-child {
            .imgBlock::after {
                display: none;
            }
        }

        .participateBlock:last-child {
            .imgBlock::before {
                display: none;
            }
        }

        .text {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 12pt;
            line-height: 14pt;
            text-align: center;
            color: #000000;
        }

        .link {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            background: -webkit-linear-gradient(#0aa7f5, #3ce7ff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .imgBlock {
            display: flex;
            justify-content: center;
            align-items: center;

            margin-bottom: 1.5em;
            position: relative;

            .newDateBadge {
                position: absolute;
                top: -2rem;
                right: -1rem;
                font-family: Montserrat;
                background-color: #0aa7f5;
                color: white;
                text-transform: uppercase;
                font-weight: 700;
                padding: 0.5rem 1rem 0.5rem 1rem;
                border-radius: 2rem;
                font-size: calc(12px + 0.08vw);
            }

            img {
                height: 6.5em;
                box-shadow: 0px 9px 16px rgba(10, 10, 10, 0.1);
                border-radius: 100%;
            }
        }

        .imgBlock::before {
            content: "";
            position: absolute;
            background-color: rgb(226, 224, 224);
            height: 1px;
            width: 100%;
            margin-left: 100%;
            z-index: -1;
        }

        .imgBlock::after {
            content: "";
            position: absolute;
            background-color: rgb(226, 224, 224);
            height: 1px;
            width: 100%;
            margin-right: 100%;
            z-index: -1;
        }
    }
}

@media (min-width: 350px) {
    .idoDetail {
        .participateBlocks {
            .participateBlock {
                width: 100%;

                max-width: 230px;
            }
        }
    }
}

@media (min-width: 960px) {
    .idoDetail {
        .firstBlock {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}

@media (min-width: 1000px) {
    .idoDetail {
        margin-top: -2em;
        .firstBlock {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

@media (max-width: 1440px) {
    .idoDetail {
        margin-top: -2.5em;
        .firstBlock {
            margin-bottom: 0.5vh;
        }

        .participateBlocks {
            padding-left: 1vw;
            padding-right: 1vw;
            margin-top: 8vh;

            .participateBlock {
                margin: 0;
                width: 20%;
            }

            .participateBlock::before {
                height: 1px;
                width: 20%;
                margin-top: 4%;
                margin-left: 4%;
                z-index: -1;
            }

            .participateBlock:last-child::before {
                display: none;
            }

            .title {
                font-size: 14pt;
                margin-bottom: 10px;
            }

            .text {
                font-size: 10pt;
                line-height: 12pt;
            }

            .link {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                background: -webkit-linear-gradient(#0aa7f5, #3ce7ff);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .imgBlock {
                display: flex;
                justify-content: center;

                img {
                    height: 5em;
                }
            }
        }
    }
}

@media (max-width: 1000px) and (orientation: portrait) {
    .idoDetail {
        .firstBlock {
            margin-top: 0px;
        }

        .participateBlocks {
            flex-direction: column;
            padding-bottom: 1em;
            width: auto;
            align-items: center;
            margin-bottom: 0;

            .participateBlock {
                width: 100%;
                margin-bottom: 3em;

                .imgBlock {
                    height: 7em;

                    img {
                        height: 100%;
                    }
                }
            }

            .participateBlock::before {
                display: none;
            }

            .participateBlock:last-child::before {
                display: none;
            }

            .imgBlock::before {
                display: none;
            }
            .imgBlock::after {
                display: none;
            }
        }
    }
}
