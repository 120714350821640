.dialog{
    
    .dialogHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        .closeIcon {
            cursor: pointer;
            font-size: 2em;
            color: rgb(177, 177, 177);
        }
    }
    
    .walletInfo {
        display: flex;
        align-items: center;
        border-radius: 10px;
        background: rgb(72,63,251);
        background: radial-gradient(circle, rgba(72,63,251,1) 0%, rgba(4,125,175,1) 100%);
        padding: 0.25em;
        padding-left: 1em;
        margin-bottom: 1em;
    

        .walletIconWrapper{
            width: 10%;
            .walletIcon {
                padding: 10px;
                background-color: white;
                color: rgb(0, 89, 131);
                border-width: 0px;
                border-radius: 1em;
                font-size: 28pt;
            }
        }
    
        .infoContainer{
            color: white;
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 90%;
            
            .infoItem{
                h3{
                    margin-bottom: 0;
                    margin-top: 0.5em;
                    font-family: 'Cabin', sans-serif;
                    font-size: 15pt;
                }

                p{
                    margin-top: 0.5em;
                }
            }
        }
    
        
    
    }
    
    
    .addressField{
        margin-bottom: 1em;
    }

    .actions{
        display: flex;
        justify-content: space-around;
        align-items: center;

        
        .element{
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
        }
    }
    
}

@media (max-width: 1000px) and (orientation: portrait){
    .dialog{
        .walletInfo{
            flex-direction: row-reverse;
            .walletIconWrapper{
                width: 35%;
                .walletIcon{
                    width:100%;
                    height: 100%;
                }
            }

            .infoContainer{
                flex-direction: column;
                align-items: flex-start;
                max-width: 60%;
            }
        }
    }
}