.Table {
    .tableSection {
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding-left: 2%;
        padding-right: 2%;
        padding-top: 1%;
        margin-bottom: 2.1%;
        overflow-x: auto;

        table {
            width: 100%;
            border-collapse: collapse;
            text-align: center;
            min-width: 600px;
            
    
            .tableHeader {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 800;
                font-size: 15pt;
    
                color: #1039a5;
                height: 2.5em;
            }
    
            .dashedLine {
                background-image: linear-gradient(to right, #1039a5 40%, rgba(255, 255, 255, 0) 0%);
                background-size: 7px 10px;
                width: 100%;
            }
    
            .infoRow {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 12pt;
    
                border-bottom: 1px solid rgba(0, 0, 0, 0.099);
                height: 4em;
    
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
    

    .paginationControl {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .sizeSelector {
            margin-right: 4.5%;
            label {
                margin-right: 0.7em;

                font-family: "Montserrat";
                font-style: normal;
                font-weight: 400;
                font-size: 11pt;
            }

            select {
                width: 3em;
                height: 2em;

                border: 1px solid #1039a5;
                border-radius: 4px;

                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 12pt;
            }
        }

        .navigation {
            display: flex;
            align-items: center;

            .counter {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 12pt;
            }

            button {
                width: 36px;
                height: 32px;
                display: flex;
                align-items: center;

                border: 1px solid #1039a5;
                border-radius: 4px;
                background-color: transparent;
                cursor: pointer;

                img {
                    width: 24px;
                    height: 24px;
                }

                &:disabled {
                    filter: grayscale(1);
                    opacity: 0.5;
                }

                &:first-child {
                    margin-right: 16px;
                }

                &:last-child {
                    margin-left: 16px;
                }
            }
        }
    }
}

