.salesPage{
    h1{
        font-family: Montserrat;
        color: #ffffff;
        font-weight: 600;
    
    }

    .pageHeader{ 
        font-size: 36pt !important;
        font-weight: 600;
        margin-bottom: 18vh;
        margin-top: 10vh;
        font-weight: 900;
    }

    .tableHeaders{
        color: rgb(0, 0, 0);
        margin-top: 2em;
        font-size: 28pt;
        font-weight: 900;
    }

    .infoPanels{
        margin-top: 5em;
        display: flex;
        justify-content: space-between;
    }
    margin-bottom: 5em;
}

@media (max-width: 1440px) {
    .salesPage{
        .pageHeader{
            font-size: 36pt !important;
            margin-top: 2em;
            
        }
        
        .tableHeaders{
            font-size: 24pt;
            margin-bottom: 0.3em;
        }
    }
}

@media (max-width: 1000px) and (orientation: portrait){
    .salesPage{
        .pageHeader{
            margin-bottom: 1em;;
        }
        .infoPanels{
            flex-direction: column;
        }
    }
}

@media (min-width: 1500px){
    .salesPage{
        .pageHeader{
            font-size: 48pt !important;
            margin-top: 20vh;
            margin-bottom: 25vh;
        }
       
    }
}