.SubscribePanel {
    display: flex;
    justify-content: space-around;
    align-items: center;

    background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    height: 9em !important;

    .subsection {
        h1 {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 32pt;
            color: #ffffff;
            margin-bottom: 0;
        }

        p {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 13pt;
            line-height: 22px;
            color: #ffffff;
            margin-top: 0;
        }

        .letterImage {
            height: 8em;
            transform: scale(1.5);
        }

        .subscribeButton {
            border: none;
            padding-left: 3em;
            padding-right: 3em;
            height: 2.8em;

            background: #ffffff;
            border-radius: 2.8em;

            font-family: "Montserrat";

            font-style: normal;
            font-weight: 600;
            font-size: 15pt;


            color: #0aa7f5;

            transition: all 0.5s;
            cursor: pointer;

            &:hover{
                transform: scale(1.05);
            }

            .arrowIco{
                margin-left: 0.5em;
            }
        }
    }
}

@media (max-width: 1440px) {
    .SubscribePanel{
        font-size: 10pt;
        .subsection{
            h1{
                font-size: 24pt;
            }

            p{
                font-size: 12pt;
            }

            .subscribeButton{
                font-size: 14pt;
            }
        }
    }
}

@media (max-width: 1000px) and (orientation: portrait) {
    .SubscribePanel{
        flex-direction: column;
        height: fit-content !important;
        .subsection{
            margin-bottom: 1em;
        }
    }
}