.mainText {
  margin-top: 2em;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 8vh;

  color: #ffffff;
}
.pageData {
  max-width: 100%;
  margin-top: 9em;
}
